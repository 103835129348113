import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticStyle:{"align-items":"flex-start"}},[_c(VCol,{staticClass:"sessions-list",attrs:{"cols":"12","md":"3","lg":"2"}},[_c(VCard,[_c(VSubheader,[_c('span',[_vm._v(_vm._s(_vm.$t("sessionsPage.sessionsListSection.sessions")))])]),_c(VDivider),_c(VList,{staticClass:"overflow-y-auto",attrs:{"three-line":"","max-height":"80vh"}},[_c(VListItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.currentSessionID),callback:function ($$v) {_vm.currentSessionID=$$v},expression:"currentSessionID"}},_vm._l((_vm.sessions),function(session){return _c(VListItem,{key:session.ID,attrs:{"value":session.ID}},[_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.sessionName(session)))])]),_c(VListItemSubtitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-calendar")]),_c('span',[_vm._v(_vm._s(_vm._f("format")(session.RecordingDate)))])],1),_c(VListItemSubtitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-clock-outline")]),_c('span',[_vm._v(_vm._s(_vm.sessionLength(session)))])],1)],1)],1)}),1)],1)],1)],1),(_vm.currentSession)?_c(VCol,{attrs:{"cols":"12","md":"9","lg":"10"}},[_c('SessionDetails',{attrs:{"session-count":_vm.sessionCount}})],1):_c(VCol,{attrs:{"cols":"12","md":"9","lg":"10"}},[_c('SessionDetailsSkeleton')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }