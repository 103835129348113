




































import Vue from "vue";
import { FilterGroup } from "@/core/models";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import SharedWithUserItem from "./SharedWithUserItem.vue";

@Component({ components: { SharedWithUserItem } })
export default class SharedWithItem extends Vue {
  @PropSync("selected", { type: Array }) users!: string[];
  @Prop({ default: {} as FilterGroup }) group!: FilterGroup;

  toggleAll(val: boolean) {
    const emails = this.group.users.map(x => x.email);
    if (val) this.users = [...new Set([...this.users, ...emails])];
    else this.users = this.users.filter(x => !emails.includes(x));
  }
  get allSelected() {
    return this.group.users.every(x => this.users.includes(x.email));
  }
  get someSelected() {
    return this.group.users.some(x => this.users.includes(x.email));
  }

  checked = false;
  indeterminate = false;
  @Watch("users", { immediate: true })
  usesChanged() {
    if (this.someSelected) {
      this.checked = true;
      if (this.allSelected) this.indeterminate = false;
      else this.indeterminate = true;
    } else this.checked = false;
  }
}
