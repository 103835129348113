









































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, PropSync } from "vue-property-decorator";

import {
  FilterGroup,
  FilterUser,
  Group,
  RemoveFromShared,
  Session,
} from "@/core/models";
import SharedWithItem from "./SharedWithItem.vue";

@Component({ components: { SharedWithItem } })
export default class EditSharedWith extends Vue {
  @Prop({ default: () => ({} as Session) }) session!: Session;
  @PropSync("usersProp", { type: Array }) users!: string[];

  @Getter("groups/loadingGroups") loading!: boolean;
  @Getter("groups/retrievedGroups") retrieved!: boolean;
  @Getter("groups/getGroups") allGroups!: Group[];
  @Getter("groups/usersManaged") usersManaged!: string[];
  @Getter("profile/getUserEmail") currentUserEmail!: string;

  @Action("groups/getGroups") getGroups!: () => Promise<void>;
  @Action("sessions/removeFromShared")
  removeFromShared!: (data: RemoveFromShared) => Promise<void>;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  get hangingUsers() {
    return this.session.SharedWith.filter(x => !this.usersManaged.includes(x));
  }

  get groups() {
    const mappedGroups = this.allGroups.map(group => {
      const users = group.Users.filter(
        x => x.Email !== this.currentUserEmail,
      ).map(
        user =>
          ({
            name: user.Username,
            email: user.Email,
            isManager: user.IsManager,
          } as FilterUser),
      );
      return {
        users,
        name: group.Name,
        isManager: group.IsManager,
      } as FilterGroup;
    });
    return mappedGroups.filter(g => g.users.length !== 0);
  }

  toRemove = "";
  removeLoading = false;
  removeAllDialog = false;
  removeOneDialog = false;
  toggleRemoveOne(u: string) {
    if (this.removeOneDialog) {
      this.removeOneDialog = false;
      this.toRemove = "";
    } else {
      this.toRemove = u;
      this.removeOneDialog = true;
    }
  }
  async removeHanging() {
    this.removeLoading = true;
    this.removeOneDialog = true;
    await this.removeFromShared({
      id: this.session.ID,
      sharedWith: [this.toRemove],
    });
    this.toRemove = "";
    this.removeLoading = false;
  }
  async removeAllHanging() {
    this.removeLoading = true;
    this.removeAllDialog = true;
    await this.removeFromShared({
      id: this.session.ID,
      sharedWith: this.hangingUsers,
    });
    this.removeLoading = false;
  }

  mounted() {
    if (!this.retrieved) this.getGroups();
  }
}
