


























import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import { InformationTooltip } from "@/components/common";
import { getPieChartData } from "../util";

// @ts-ignore
import * as PiechartOutlabels from "chartjs-plugin-piechart-outlabels";
import DoughnutChart from "@/components/charts/base-charts/DoughnutChart.vue";
import { ChartOptions } from "chart.js";
import { HowToPopup } from "@/components/common";
import Legend from "@/components/sessions/cards/components/Legend.vue";

@Component({
  components: {
    HowToPopup,
    DoughnutChart,
    PiechartOutlabels,
    InformationTooltip,
    Legend,
  },
})
export default class SentimentRatio extends Vue {
  @Getter("theme") theme!: any;

  @Getter("currentSession/positive") positive!: number;
  @Getter("currentSession/negative") negative!: number;
  @Getter("currentSession/neutral") neutral!: number;
  @Getter("currentSession/hasSentence") hasSentence!: number;

  get chartData() {
    const positiveColor = this.theme.primary + "";
    const negativeColor = this.theme.prezpRed + "";
    const neutralColor = this.theme.accent + "";
    let labels = ["Positive", "Negative", "Neutral"];
    let data = [this.positive, this.negative, this.neutral];
    return getPieChartData(labels, data, [
      positiveColor,
      negativeColor,
      neutralColor,
    ]);
  }

  chartOptions: ChartOptions = {
    responsive: true,
    cutoutPercentage: 60,
    maintainAspectRatio: false,
    tooltips: { enabled: true },
    layout: { padding: { top: 20, bottom: 20 } },
    plugins: {
      legend: false,
      datalabels: false,
      outlabels: {
        text: "%p",
        stretch: -20,
        lineWidth: 0,
        color: "white",
        borderRadius: 5,
        lineColor: "transparent",
        padding: { left: 10, right: 10 },
        backgroundColor: "#777",
        font: {
          minSize: 14,
          maxSize: 14,
          weight: "bold",
          resizable: true,
        },
        display: (chart: any) => {
          const dataId = chart.dataIndex;
          const value = chart.dataset.data[dataId];
          return value !== 0;
        },
      },
    },
  };
}
