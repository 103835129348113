
































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import filters from "@/components/sessions/filters";
import { Presentation, SelectItem, Session } from "@/core/models";

@Component
export default class SessionFilters extends Vue {
  @Prop({ default: () => [] }) sessions!: Session[];
  @Prop({ default: () => false }) showsPres!: boolean;
  @Prop({ default: () => false }) presLoading!: boolean;
  @Prop({ default: () => [] }) presentations!: Presentation[];

  // Date filter properties
  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substring(0, 10);
  selectedDateOption = "between";
  startDateDialog = false;
  endDateDialog = false;
  dateFilter = false;
  get dateOptions() {
    return [
      {
        text: this.$t("sess.filters.date.before").toString(),
        value: "before",
      },
      {
        text: this.$t("sess.filters.date.after").toString(),
        value: "after",
      },
      {
        text: this.$t("sess.filters.date.between").toString(),
        value: "between",
      },
    ];
  }

  // Duration filter
  durationFilter = false;
  minTime = 1;
  maxTime = 10;
  selectedDurationOption = "between";
  get durationOptions() {
    return [
      {
        text: this.$t("sess.filters.duration.longer").toString(),
        value: "longer",
      },
      {
        text: this.$t("sess.filters.duration.shorter").toString(),
        value: "shorter",
      },
      {
        text: this.$t("sess.filters.duration.between").toString(),
        value: "between",
      },
    ];
  }

  // Name filter
  nameFilter = false;
  term = "";
  selectedNameOption = "includes";
  caseInsensitive = false;
  get nameOptions() {
    return [
      {
        text: this.$t("sess.filters.name.startsWith").toString(),
        value: "startsWith",
      },
      {
        text: this.$t("sess.filters.name.endsWith").toString(),
        value: "endsWith",
      },
      {
        text: this.$t("sess.filters.name.includes").toString(),
        value: "includes",
      },
      {
        text: this.$t("sess.filters.name.exact").toString(),
        value: "exact",
      },
    ];
  }

  // Score filter
  // scoreFilter = false;
  minScore = 10;
  maxScore = 60;
  selectedScoreOption = "range";
  get scoreOptions() {
    return [
      {
        text: this.$t("sess.filters.score.greater").toString(),
        value: "greater",
      },
      {
        text: this.$t("sess.filters.score.less").toString(),
        value: "less",
      },
      {
        text: this.$t("sess.filters.score.range").toString(),
        value: "range",
      },
    ];
  }

  presFilter = false;
  selectedPres = "";
  get presItems() {
    if (!this.presentations.length) return [];
    return this.presentations.map(
      x => ({ text: x.Name || x.File.Name, value: x.ID } as SelectItem<number>),
    );
  }

  filterDialog = false;
  filterSessions() {
    let res = this.sessions;
    filters
      .filter(x => x.Enabled)
      .forEach(x => {
        switch (x.ID) {
          case "date":
            res = x.Apply(
              res,
              this.startDate,
              this.endDate,
              this.selectedDateOption,
            );
            break;

          case "duration":
            res = x.Apply(
              res,
              this.minTime,
              this.maxTime,
              this.selectedDurationOption,
            );
            break;

          case "name":
            res = x.Apply(
              res,
              this.term,
              this.selectedNameOption,
              this.caseInsensitive,
            );
            break;

          case "score":
            res = x.Apply(
              res,
              this.minScore,
              this.maxScore,
              this.selectedScoreOption,
            );
            break;

          case "pres":
            res = x.Apply(res, this.selectedPres);
            break;

          default:
            break;
        }
      });

    this.$emit("filter", res);
  }

  disableFilters() {
    this.dateFilter = false;
    this.durationFilter = false;
    this.nameFilter = false;
    // this.scoreFilter = false;
    this.presFilter = false;
    this.enableFilters();
  }

  enableFilters() {
    filters.find(f => f.ID === "date")!.Enabled = this.dateFilter;
    filters.find(f => f.ID === "duration")!.Enabled = this.durationFilter;
    filters.find(f => f.ID === "name")!.Enabled = this.nameFilter;
    // filters.find(f => f.ID === "score")!.Enabled = this.scoreFilter;
    filters.find(f => f.ID === "pres")!.Enabled = this.presFilter;
    this.countEnabledFilters();
    this.filterSessions();
    this.filterDialog = false;
  }

  @Watch("sessions")
  sessionsChanged() {
    this.filterSessions();
  }

  numEnabled = "0";
  countEnabledFilters() {
    let count = filters.filter(x => x.Enabled).length.toString() || "0";
    this.numEnabled = count;
  }
}
