import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{attrs:{"value":_vm.user.email},on:{"change":_vm.toggleUser}},[_c(VListItemAction,[_c(VCheckbox,{on:{"click":_vm.toggleUser,"input":_vm.toggleUser,"change":_vm.toggleUser},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),(_vm.user.isManager)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.$t("roles.manager"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }