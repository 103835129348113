import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"grey","dark":"","text":""}},on),[_c('span',[_vm._v(_vm._s(_vm.currentOption))])])]}}])},[_c(VList,_vm._l((_vm.options),function(option,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.setLimit(option)}}},[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(option == 42 ? _vm.$t("charts.all") : option))])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }