


















import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import { SessionScore } from "@/components/charts";

@Component({ components: { SessionScore } })
export default class AudioAnalysisDetails extends Vue {
  @Getter("currentSession/name") title!: string;
  @Getter("currentSession/score") score!: number;

  get sessionScore() {
    return Math.round(this.score * 10000) / 100;
  }
}
