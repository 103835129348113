
import Vue from "vue";
import { Getter } from "vuex-class";
import { ChartOptions } from "chart.js";
import { Bar, mixins } from "vue-chartjs";
// @ts-ignore
import DraggablePlugin from "chartjs-plugin-draggable";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { Component, Prop, Watch } from "vue-property-decorator";

import { ChartClick } from "@/core/models";

@Component({
  extends: Bar,
  mixins: [mixins.reactiveProp],
})
export default class VoiceLineChart extends Vue {
  @Getter("theme") theme!: any;
  @Prop({ default: () => "" }) label!: string;
  @Prop({ default: () => 100 }) maxTicks!: number;
  @Prop({ default: () => false }) filled!: boolean;
  @Prop({ default: () => [] }) annotations!: any[];

  @Prop({ default: () => {} }) chartData!: any;

  renderChart!: (chartData: any, options: any) => void;
  addPlugin!: (plugin: any) => void;
  update!: (config?: any) => void;

  handleClick(evt: any) {
    const point = this.$data._chart.getElementAtEvent(evt)[0];
    if (point) {
      const value = point._index + 1;
      this.$emit("chart-click", { type: "index", value } as ChartClick);
    } else {
      const { left, right, top, bottom } = this.$data._chart.chartArea;
      if (
        evt.offsetX > left &&
        evt.offsetX < right &&
        evt.offsetY > top &&
        evt.offsetY < bottom
      ) {
        const value = (evt.offsetX - left) / (right - left);
        this.$emit("chart-click", { type: "offset", value } as ChartClick);
      }
    }
  }

  @Watch("annotations")
  annotationsChanged() {
    this.options.annotation!.annotations = this.annotations;
    this.renderChart(this.chartData, this.options);
  }

  @Watch("chartData")
  dataChanged() {
    this.options.annotation!.annotations = this.annotations;
    this.renderChart(this.chartData, this.options);
  }

  mounted() {
    this.addPlugin(DraggablePlugin);
    this.addPlugin(AnnotationPlugin);
    this.renderChart(this.chartData, this.options);
    setTimeout(() => this.renderChart(this.chartData, this.options), 1000);
  }

  get options(): ChartOptions {
    return {
      onClick: this.handleClick,
      animation: { duration: 0 },
      annotation: { annotations: this.annotations },
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      tooltips: { enabled: false, displayColors: false },
      layout: { padding: { top: 10, bottom: 10, left: 10, right: 10 } },
      elements: {
        point: { radius: 4, hitRadius: 6, hoverRadius: 8 },
        line: { fill: this.filled },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: { display: true, labelString: this.label },
            gridLines: {
              drawBorder: false,
              borderDash: [0, 0],
              color: this.theme.lightgray2,
            },
            ticks: {
              min: -this.maxTicks,
              max: this.maxTicks,
              padding: 10,
              maxTicksLimit: 10,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: { display: false, labelString: "" },
            gridLines: { display: false, drawBorder: false },
            ticks: {
              display: false,
              padding: 10,
              maxTicksLimit: 5,
              maxRotation: 0,
            },
          },
        ],
      },
    };
  }
}
