import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListGroup,{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItem,[_c(VListItemAction,{staticClass:"mr-4"},[_c(VCheckbox,{attrs:{"indeterminate":_vm.indeterminate,"color":_vm.allSelected ? 'primary' : 'accent'},on:{"change":function (val) { return _vm.toggleAll(val); }},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],1),_c(VListItemIcon,{staticClass:"mr-4"},[_c(VIcon,[_vm._v("mdi-account-group")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.group.name))]),(_vm.group.isManager)?_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm.$t("roles.manager")))])]):_vm._e()],1)],1)]},proxy:true}])},[_c(VList,{staticClass:"pl-6"},[_c(VListItemGroup,_vm._l((_vm.group.users),function(user,i){return _c('SharedWithUserItem',{key:("user-" + i),attrs:{"user":user,"selected":_vm.users},on:{"update:selected":function($event){_vm.users=$event}}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }