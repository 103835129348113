

























import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { ChartEntry } from "@/core/models/sessions";
import { InformationTooltip } from "@/components/common";
import { getDataFromChartEntry, hexToRgba } from "../util";
import LineChart from "@/components/charts/base-charts/LineChart.vue";

@Component({ components: { LineChart, InformationTooltip } })
export default class HeadMovement extends Vue {
  @Getter("theme") theme!: any;
  @Getter("currentSession/id") id!: number;
  @Getter("currentSession/handAvg") average!: number;
  @Getter("currentSession/handMovement") apiData!: ChartEntry[];

  get key() {
    return `hand-chart-${this.id}`;
  }

  get avg() {
    const val =
      this.average === 0
        ? this.apiData.reduce((cum, cur) => cum + cur.Value, 0) /
          this.apiData.length
        : this.average;
    return Math.round(val * 100) / 100;
  }

  get chartData() {
    const hexColor = this.$vuetify.theme.themes.light.primary + "";
    const color = hexToRgba(hexColor, 0.5);
    return getDataFromChartEntry(this.apiData, color);
  }

  get avgAnnotation() {
    return {
      id: "avg",
      type: "line",
      borderWidth: 2,
      value: this.avg,
      mode: "horizontal",
      scaleID: "y-axis-0",
      borderDash: [10, 4],
      borderColor: this.theme.darkgray,
    };
  }

  get chartOptions() {
    return {
      annotation: { annotations: [this.avgAnnotation] },
      layout: { padding: { top: 10, bottom: 10, left: 10, right: 10 } },
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      elements: {
        point: { radius: 4, hitRadius: 6, hoverRadius: 8 },
        line: { fill: false },
      },
      tooltips: { displayColors: false },
      scales: {
        yAxes: [
          {
            scaleLabel: { display: true, labelString: "Distance" },
            gridLines: { borderDash: [5, 10], drawBorder: false },
            ticks: { padding: 10, maxTicksLimit: 4 },
          },
        ],
        xAxes: [
          {
            scaleLabel: { display: true, labelString: "Time in %" },
            gridLines: { display: false, drawBorder: false },
            ticks: {
              display: false,
              padding: 10,
              maxTicksLimit: 5,
              maxRotation: 0,
            },
          },
        ],
      },
    };
  }
}
