









import Vue from "vue";
import { Getter } from "vuex-class";
import { getPercentageChartData } from "../util";
import { Component, Prop } from "vue-property-decorator";
import DoughnutChart from "@/components/charts/base-charts/DoughnutChart.vue";

@Component({
  components: { DoughnutChart },
})
export default class SessionScore extends Vue {
  @Getter("theme") theme!: any;
  @Prop({ default: () => 0 }) score!: number;

  get chartData(): object {
    let color1 = this.theme.primary + "";
    let color2 = this.theme.accent + "";
    return getPercentageChartData(this.score, [color1, color2]);
  }

  get label() {
    return this.score;
  }

  chartOptions: object = {
    tooltips: { enabled: false },
    cutoutPercentage: 50,
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    elements: { center: { text: "--%" } },
  };
}
