


























































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import Legend from "./components/Legend.vue";
import Sentence from "./components/Sentence.vue";
import PagesArrows from "@/components/common/PagesArrows.vue";
import { InformationTooltip } from "@/components/common";
import { Sentiment, SentimentSentence } from "@/core/models/sessions";

type Filter = (s: SentimentSentence) => boolean;
@Component({
  components: {
    InformationTooltip,
    PagesArrows,
    Sentence,
    Legend,
  },
})
export default class SentimentSentences extends Vue {
  @Getter("audio/time") time!: number;
  @Getter("currentSession/text") text!: string;
  @Getter("currentSession/sentences") sentences!: SentimentSentence[];

  idx: number | null = null;
  get currentSentence() {
    if (this.idx === null) return null;
    return this.filteredSentences[this.idx];
  }
  @Watch("time")
  timeUpdate() {
    if (this.filteredSentences.length === 0) {
      this.idx = null;
      return;
    }

    const timems = this.time * 1000;
    if (timems === 0) {
      this.idx = 0;
      return;
    }

    const newIdx = this.filteredSentences.findIndex(
      x => timems <= x.Offset + x.Duration,
    );
    if (newIdx === -1) this.idx = this.filteredSentences.length - 1;
    else this.idx = newIdx;
  }

  filters: Filter[] = [
    () => true,
    s => s.Sentiment === Sentiment.Positive && s.PositiveScore > 75,
    s => s.Sentiment === Sentiment.Positive,
    s => s.Sentiment === Sentiment.Neutral,
    s => s.Sentiment === Sentiment.Negative,
    s => s.Sentiment === Sentiment.Negative && s.NegativeScore > 75,
  ];
  get filterNames() {
    return [
      this.$t("sentences.filters.none").toString(),
      this.$t("sentences.filters.vpos").toString(),
      this.$t("sentences.pos").toString(),
      this.$t("sentences.neu").toString(),
      this.$t("sentences.neg").toString(),
      this.$t("sentences.filters.vneg").toString(),
    ];
  }
  filterName = "None";
  sentencesFilter: Filter = () => true;
  get filteredSentences() {
    return this.sentences.filter(this.sentencesFilter);
  }
  setFilter(key = 0) {
    this.filterName = this.filterNames[key];
    this.sentencesFilter = this.filters[key];
    if (this.filteredSentences.length === 0) this.idx = null;
    else this.idx = 0;
  }

  @Watch("sentences", { immediate: true })
  sentencesChanged() {
    this.setFilter(0);
    if (this.filteredSentences.length === 0) this.idx = null;
    else this.idx = 0;
  }
}
