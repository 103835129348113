





















import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { Component } from "vue-property-decorator";

@Component
export default class DataPointsSelect extends Vue {
  @Getter("currentSession/limit") optionValue!: number;
  @Action("currentSession/setLimit") setLimit!: Function;

  options = [30, 50, 100, 200, 300, 500, 42];
  get currentOption() {
    return this.$t("charts.points", {
      n: this.optionValue == 42 ? this.$t("charts.all") : this.optionValue,
    });
  }

  created() {
    this.setLimit(50);
  }
}
