













































import Vue from "vue";
import { Duration } from "luxon";
import { Action, Getter } from "vuex-class";
import { Component, Watch, Prop } from "vue-property-decorator";

import { Session } from "@/core/models/sessions";
import { AudioPlayer } from "@/components/common";
import { SessionDetails, SessionDetailsSkeleton } from "@/components/sessions";

@Component({
  components: {
    SessionDetails,
    SessionDetailsSkeleton,
    AudioPlayer,
  },
})
export default class Sessions extends Vue {
  @Prop({ default: () => [] }) sessions!: Session[];
  @Getter("showSnackbar") showSnackbar!: boolean;
  @Getter("snackbarText") snackbarText!: boolean;
  @Action("currentSession/setSession") setCurrentSession!: Function;

  get sessionCount() {
    return this.sessions.length;
  }

  sessionLength(session: Session) {
    const d = Duration.fromMillis(session.SessionLength * 1000);
    return d.toFormat("mm:ss");
  }
  sessionName(session: Session) {
    return session.Name || session.PDFInfo.Name || "No name";
  }

  currentSessionID = 0;
  @Watch("currentSessionID")
  onCurrentUpdate() {
    console.log("i am");
    const session = this.sessions.find(s => s.ID === this.currentSessionID);
    this.setCurrentSession(session);
  }
  created() {
    if (this.sessions.length === 0) return;
    const session = this.sessions[0].ID;
    this.setCurrentSession(session);
  }
}
