










































































































































import Vue from "vue";
import { ChartData } from "chart.js";
import { Getter, Action } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import { hexToRgba, reduceData } from "@/components/charts/util";
import { InformationTooltip } from "@/components/common";
import VoiceLineChart from "@/components/charts/base-charts/VoiceLineChart.vue";
import {
  User,
  Session,
  Interval,
  ChartClick,
  ChartEntry,
  BaselineAnalysis,
} from "@/core/models";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({ components: { VoiceLineChart, InformationTooltip } })
export default class VoicePitch extends Vue {
  @Getter("theme") theme!: any;

  @Getter("audio/total") totalTime!: number;
  @Getter("audio/time") currentTime!: number;
  @Getter("audio/computedProgress") progress!: number;
  @Getter("audio/timeFormatted") timeFormatted!: string;
  @Action("audio/setProgress") setProgress!: Function;

  @Getter("sessions/benchmark") benchmark!: Session;
  @Getter("sessions/benchmarkUser") benchmarkUser!: User;

  @Getter("currentSession/id") id!: number;
  @Getter("currentSession/zoom") zoom!: boolean;
  @Getter("currentSession/limit") limit!: number;
  @Getter("currentSession/pitchAvg") average!: number;
  @Getter("currentSession/pitch") allData!: ChartEntry[];
  @Getter("currentSession/length") sessionLength!: number;
  @Getter("currentSession/interval") zoomInterval!: Interval;

  @Getter("profile/baseline") baseline!: BaselineAnalysis;

  VRMode = LocalStorage.getVRMode();

  @Watch("zoom")
  zoomChanged() {
    this.updateLinePosition();
  }

  get apiData() {
    let entries = this.allData;
    if (this.zoom) {
      const { start, end } = this.zoomInterval;
      entries = this.allData.filter(
        x => start <= x.Timestamp && x.Timestamp <= end,
      );
    }
    return reduceData(entries, this.limit);
  }

  get avg() {
    if (!this.zoom) return this.average;
    const sum = this.apiData
      .map(x => x.Value)
      .reduce((cum, cur) => cum + cur, 0);
    const val = sum / this.apiData.length;
    return Math.round(val * 100) / 100;
  }

  get key() {
    return `pitch-chart-${this.id}`;
  }

  get benchAvg() {
    if (!this.benchmark) return 0;
    return this.benchmark.AudioAnalysis.AveragePitch;
  }

  rounder = (entry: ChartEntry) => Math.round(entry.Value * 100) / 100;
  get chartColor() {
    const hexColor = this.theme.primary + "";
    return hexToRgba(hexColor, 0.7);
  }
  get chartData() {
    const labels = this.apiData.map((_, i) => i + 1);
    const userData = this.apiData.map(this.rounder);
    let benchData: number[] = [];
    if (this.benchmark) {
      const benchEntries = this.benchmark.AudioAnalysis.Pitch;
      if (this.limit !== 42)
        benchData = reduceData(benchEntries, this.limit).map(this.rounder);
      else
        benchData = reduceData(benchEntries, userData.length).map(this.rounder);
    }
    const benchColorHex = this.theme.main + "";
    const benchColor = hexToRgba(benchColorHex, 0.2);

    const data: ChartData = {
      labels,
      datasets: [
        {
          data: userData,
          borderColor: this.chartColor,
          backgroundColor: this.chartColor,
        },
        {
          data: benchData,
          borderColor: benchColor,
          backgroundColor: benchColor,
          pointBackgroundColor: benchColor,
        },
      ],
    };
    return data;
  }

  time = 0;
  @Watch("progress")
  updateLinePosition() {
    const segStart = this.zoom ? this.zoomInterval.start : 0;
    const segEnd = this.zoom
      ? this.zoomInterval.end
      : this.sessionLength * 1000;
    const currTime = this.currentTime * 1000;
    const dataPointAmount = this.apiData.length;

    let currScale = 0;
    if (currTime < segStart) currScale = 0;
    else if (currTime > segEnd) currScale = 1;
    else currScale = (currTime - segStart) / (segEnd - segStart);

    this.time = currScale * dataPointAmount;
  }

  handleClick(evt: ChartClick) {
    const value =
      evt.type === "index" ? (evt.value - 1) / this.apiData.length : evt.value;
    const segLen = this.zoom
      ? this.zoomInterval.end - this.zoomInterval.start
      : this.sessionLength * 1000;
    const ofTotal = segLen / (this.sessionLength * 1000);
    const offset = this.zoom
      ? this.zoomInterval.start / (this.sessionLength * 1000)
      : 0;

    this.setProgress(value * ofTotal + offset);
  }

  get timeAnnotation() {
    return {
      value: this.time,
      type: "line",
      borderWidth: 2,
      mode: "vertical",
      scaleID: "x-axis-0",
      borderColor: this.theme.main,
    };
  }
  get avgAnnotation() {
    return {
      value: this.avg,
      type: "line",
      borderWidth: 2,
      mode: "horizontal",
      scaleID: "y-axis-0",
      borderDash: [10, 4],
      borderColor: this.theme.darkgray,
    };
  }

  get baselineAnnotation() {
    if (!this.baseline.analyzed) return {};
    const value = this.baseline.pitch;
    return {
      value,
      type: "line",
      borderWidth: 2,
      mode: "horizontal",
      scaleID: "y-axis-0",
      borderDash: [10, 4],
      borderColor: this.theme.green,
    };
  }

  get annotations() {
    return [this.timeAnnotation, this.avgAnnotation, this.baselineAnnotation];
  }
}
