

















































































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";
import {
  FileObject,
  LookupWord,
  LookupWordType,
  SessionScores,
} from "@/core/models";
import api from "@/core/utils/api";
import {
  SessionVideoPlayer,
  VideoRecorderWithBottomToolbar,
} from "@/components/common";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({
  components: { VideoRecorderWithBottomToolbar, SessionVideoPlayer },
})
export default class WPMCard extends Vue {
  @Getter("currentSession/id") id!: number;
  @Getter("currentSession/scores") scores?: SessionScores;
  @Getter("currentSession/wpm") wpm!: number;
  @Getter("currentSession/pausesPerMinute") pausesPerMinute!: number;
  @Getter("currentSession/lookupWords") lookupWords!: LookupWord[];
  @Getter("currentSession/length") length!: number;
  @Getter("currentSession/feedback") feedback!: FileObject | null;
  @Action("sessions/updateShared") updateSharedSession!: (data: any) => void;
  @Action("currentSession/updateFeedback") updateFeedback!: (data: any) => void;
  @Prop({ default: () => false }) enableFeedback!: boolean;

  deleting = false;
  openRecordFeedbackDialog = false;
  openViewFeedbackDialog = false;
  openDeleteFeedbackDialog = false;
  playFeedback = true;
  stopFeedback = false;
  feedbackUrl: string | undefined = undefined;

  VRMode = LocalStorage.getVRMode();

  toggleRecordFeedbackDialog() {
    this.openRecordFeedbackDialog = true;
  }

  toggleViewFeedbackDialog() {
    this.feedbackUrl = this.feedback?.URI;
    this.openViewFeedbackDialog = true;
  }

  toggleDeleteFeedbackDialog() {
    this.openDeleteFeedbackDialog = true;
  }

  get ititle() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.infoTitle");
  }
  get itext() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.infoText");
  }
  get headline() {
    return this.$t("sessionsPage.sessionsListSection.scoresCard.cardTitle");
  }

  uploading = false;

  async deleteFeedback() {
    this.deleting = true;
    try {
      await api
        .delete("/api/sessions/feedback/" + this.id.toString())
        .then(session => {
          console.log("deleted", session);
          this.updateFeedback(session);
          this.updateSharedSession({ sessionId: this.id, hasFeedback: false });
        });
    } catch (error) {
      console.log(error);
    }
    this.deleting = false;
    this.openDeleteFeedbackDialog = false;
  }

  async uploadFeedback({ blob }: { blob: Blob }) {
    this.uploading = true;
    try {
      const data = new FormData();
      data.append("sessionId", this.id.toString());
      data.append("feedback", blob);
      await api
        .post("/api/sessions/feedback", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(session => {
          console.log("uploaded", session);
          this.updateFeedback(session);
          this.updateSharedSession({ sessionId: this.id, hasFeedback: true });
        });
    } catch (error) {
      console.log(error);
    }
    this.uploading = false;
    this.openRecordFeedbackDialog = false;
  }

  interpolateColor(
    color1: [number, number, number],
    color2: [number, number, number],
    factor: number = 0.5,
  ): [number, number, number] {
    const result = color1.slice();
    return [
      Math.round(result[0] + factor * (color2[0] - color1[0])),
      Math.round(result[1] + factor * (color2[1] - color1[1])),
      Math.round(result[2] + factor * (color2[2] - color1[2])),
    ];
  }

  r2h(rgb: [number, number, number]) {
    return (
      "#" +
      ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2])
        .toString(16)
        .slice(1)
    );
  }

  scoreFactor(score: number) {
    return score / 10;
  }

  scoreColor(score: number) {
    const rgb = this.interpolateColor(
      [235, 61, 52],
      [25, 212, 50],
      this.scoreFactor(score),
    );
    return this.r2h(rgb);
  }

  fillerWordsAsString(): string {
    let text = " ";
    this.lookupWords
      .filter(word => word.type == LookupWordType.filler)
      .forEach(word => (text += word.text + " (" + word.occurrences + ") "));
    return text;
  }

  repetitionWordsAsString(): string {
    let text = " ";
    let nr = 0;
    // this.lookupWords.forEach(element => {
    //   console.log(
    //     element.text + " " + element.occurrences / (this.length / 60),
    //   );
    // });
    this.lookupWords
      .filter(
        word =>
          word.type == LookupWordType.default &&
          word.occurrences / (this.length / 60) > 3,
      )
      .sort((a, b) => b.occurrences - a.occurrences)
      .forEach(word => {
        if (nr < 10)
          text +=
            word.text +
            " (" +
            Math.round((word.occurrences / (this.length / 60)) * 10) / 10 +
            ") ";
        nr++;
      });
    if (nr == 10) text += "(...)";
    return text;
  }

  get fillerWordsPerMinute() {
    const wordCount =
      this.lookupWords.filter(x => x.type === LookupWordType.filler).length ||
      0;
    return Math.round((wordCount / (this.length / 60)) * 10) / 10;
  }

  get scoreItems() {
    if (!this.scores) return [];

    const fillerWords = this.lookupWords.filter(
      word => word.type == LookupWordType.filler,
    );

    const fillerWordList =
      fillerWords.length > 0
        ? this.$t(
            "sessionsPage.sessionsListSection.scoresCard.fillerTipWordlist",
          ) + this.fillerWordsAsString()
        : "";

    return [
      {
        icon: "mdi-star",
        value: Math.round(this.scores.overallScore * 10) / 10,
        title: this.$t("sessionsPage.sessionsListSection.scoresCard.overall"),
        tip: this.$t("sessionsPage.sessionsListSection.scoresCard.overallTip"),
      },
      {
        icon: "mdi-speedometer",
        value: Math.round(this.scores.speechSpeedScore * 10) / 10,
        title: this.$t("sessionsPage.sessionsListSection.scoresCard.wpm"),
        tip:
          this.$t("sessionsPage.sessionsListSection.scoresCard.wpmTip", {
            value: this.wpm,
          }).toString() +
          (this.wpm > 120
            ? this.$t(
                "sessionsPage.sessionsListSection.scoresCard.wpmSlowerTip",
              ).toString()
            : "") +
          (this.wpm < 90
            ? this.$t(
                "sessionsPage.sessionsListSection.scoresCard.wpmFasterTip",
              ).toString()
            : ""),
      },
      {
        icon: "mdi-pause-circle",
        value: Math.round(this.scores.pausesScore * 10) / 10,
        title: this.$t("sessionsPage.sessionsListSection.scoresCard.pauses"),
        tip:
          this.$t("sessionsPage.sessionsListSection.scoresCard.pausesTip", {
            value: this.pausesPerMinute,
          }).toString() +
          (this.pausesPerMinute > 3
            ? this.$t(
                "sessionsPage.sessionsListSection.scoresCard.lessPausesTip",
              )
            : "") +
          (this.pausesPerMinute < 2
            ? this.$t(
                "sessionsPage.sessionsListSection.scoresCard.morePausesTip",
              )
            : ""),
      },
      {
        icon: "mdi-repeat",
        value: Math.round(this.scores.repetitionScore * 10) / 10,
        title: this.$t(
          "sessionsPage.sessionsListSection.scoresCard.repetition",
        ),
        tip:
          this.$t(
            "sessionsPage.sessionsListSection.scoresCard.repetitionTip",
          ).toString() + this.repetitionWordsAsString(),
      },
      {
        icon: "mdi-delete",
        value: Math.round(this.scores.fillerWordsScore * 10) / 10,
        title: this.$t("sessionsPage.sessionsListSection.scoresCard.filler"),
        tip:
          this.$t("sessionsPage.sessionsListSection.scoresCard.fillerTip", {
            value: this.fillerWordsPerMinute,
          }).toString() +
          fillerWordList +
          this.$t(
            "sessionsPage.sessionsListSection.scoresCard.fillerTipContinued",
          ).toString(),
      },
      {
        icon: "mdi-account-voice",
        value: Math.round(this.scores.speakingClearlyScore * 10) / 10,
        title: this.$t("sessionsPage.sessionsListSection.scoresCard.speaking"),
        tip: this.$t(
          "sessionsPage.sessionsListSection.scoresCard.speakingTip",
          { value: Math.round(this.scores.speakingClearlyScore * 10) },
        ).toString(),
      },
    ];
  }
}
