
import Vue from "vue";
import { Getter } from "vuex-class";
import { ChartOptions } from "chart.js";
import { Doughnut, mixins } from "vue-chartjs";
import { Component, Prop } from "vue-property-decorator";
import VueI18n from "vue-i18n";
import LocalStorage from "@/core/utils/LocalStorage";

const { reactiveProp } = mixins;

@Component({
  extends: Doughnut,
  mixins: [reactiveProp],
})
export default class DoughnutChartLabel extends Vue {
  @Getter("theme") theme!: any;
  @Prop({ default: () => {} }) chartData!: any;
  @Prop({ default: () => 0 }) label!: string;
  @Prop({ default: () => 0 }) subtitle!: string | VueI18n.TranslateResult;
  @Prop({ default: () => false }) isRecorder!: boolean;

  VRMode = LocalStorage.getVRMode();

  renderChart!: (chartData: any, options: any) => void;
  addPlugin!: (plugin: any) => void;
  renderLabel(chart: any): void {
    let width = chart.chart.width as number,
      height = chart.chart.height as number,
      ctx = chart.chart.ctx as CanvasRenderingContext2D;

    ctx.clearRect(0, 0, width, height);
    ctx.restore();

    let fontSize = (height / 114).toFixed(2);
    ctx.textBaseline = "middle";
    ctx.font = fontSize + "em sans-serif";
    ctx.fillStyle =
      this.VRMode && this.isRecorder
        ? this.theme.other + ""
        : this.theme.main + "";

    let text = this.label;
    let { actualBoundingBoxAscent: textHeight } = ctx.measureText(text);
    let textX = Math.round((width - ctx.measureText(text).width) / 2);
    let textY = height / 2;
    ctx.fillText(text, textX, textY);

    fontSize = (height / 200).toFixed(2);
    ctx.font = "bold " + fontSize + "em sans-serif";
    text = this.subtitle.toString();
    ctx.fillStyle =
      this.VRMode && this.isRecorder
        ? this.theme.other + ""
        : this.theme.darkgray + "";
    textX = Math.round((width - ctx.measureText(text).width) / 2);
    textY = height / 2 + textHeight * 2 + 5;

    ctx.fillText(text, textX, textY);
    ctx.save();
  }

  addRenderLabelPlugin() {
    let fn = this.renderLabel;
    this.addPlugin({
      id: "render-label",
      beforeDraw: (chart: any) => fn(chart),
      beforeUpdate: (chart: any) => fn(chart),
    });
  }

  mounted() {
    this.addRenderLabelPlugin();
    this.renderChart(this.chartData, this.options);
  }

  get options(): ChartOptions {
    return {
      tooltips: { enabled: false },
      cutoutPercentage: 80,
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      circumference: 1.6 * Math.PI,
      rotation: 0.7 * Math.PI,
      plugins: {
        legend: false,
        datalabels: false,
        outlabels: false,
      },
    };
  }
}
