


















































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class SessionDetails extends Vue {
  @Prop({ default: () => 0 }) id!: number;
  @Prop({ default: () => false }) canDelete!: boolean;
  @Action("sessions/deleteSession")
  deleteSession!: (i: number) => Promise<void>;

  loading = false;
  confirmDeleteDialog = false;
  async confirmDelete() {
    if (!this.canDelete) return;
    this.loading = true;
    await this.deleteSession(this.id);
    this.confirmDeleteDialog = false;
    this.$emit("deleted");
    this.loading = false;
  }
}
