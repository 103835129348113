













































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import PausesCard from "../cards/PausesCard.vue";
import { PausesRatio } from "@/components/charts";
import { TranscriptionSlides } from "@/components/common";
import { VoicePitch } from "@/components/charts";
import WPMCard from "@/components/sessions/cards/WPMCard.vue";
import LocalStorage from "@/core/utils/LocalStorage";
import { TranscriptionSlide } from "@/core/models";

@Component({
  components: {
    WPMCard,
    VoicePitch,
    PausesCard,
    PausesRatio,
    TranscriptionSlides,
  },
})
export default class AudioAnalysisDetails extends Vue {
  @Getter("currentSession/volumeAvg") volumeAvg!: number;
  @Getter("currentSession/audioCompleted") isCompleted!: boolean;
  @Getter("currentSession/videoUrl") videoUrl!: string;
  @Getter("currentSession/currentSlide") currentSlide!: TranscriptionSlide;

  VRMode = LocalStorage.getVRMode();
}
