import { Session } from "@/core/models/sessions";

abstract class Filter {
  public ID: string;
  public Name: string;
  public Enabled: boolean;
  public abstract Apply: (data: Session[], ...args: any[]) => Session[];

  constructor(id: string, name: string, enabled: boolean) {
    this.ID = id;
    this.Name = name;
    this.Enabled = enabled;
  }
}

class DateFilter extends Filter {
  Apply = (
    data: Session[],
    startDate: string,
    endDate: string,
    option: string,
  ) => {
    if (option == "before") startDate = "01/01/2010";
    const start = new Date(Date.parse(startDate));
    const end = new Date(Date.parse(endDate));
    const newData = data.filter(x => {
      const sessionDate = new Date(x.RecordingDate);
      return sessionDate >= start && sessionDate <= end;
    });
    return newData;
  };

  constructor() {
    super("date", "Date filter", false);
  }
}

class DurationFilter extends Filter {
  Apply = (
    data: Session[],
    minTime: number,
    maxTime: number,
    option: string,
  ) => {
    minTime *= 60;
    maxTime *= 60;
    if (option == "between")
      return data.filter(
        x => x.SessionLength >= minTime && x.SessionScore <= maxTime,
      );
    else if (option == "longer")
      return data.filter(x => x.SessionLength >= minTime);
    else return data.filter(x => x.SessionLength <= maxTime);
  };

  constructor() {
    super("duration", "Duration filter", false);
  }
}

class NameFilter extends Filter {
  Apply = (
    data: Session[],
    term: string,
    option: string,
    caseInsensitive: boolean,
  ) => {
    if (caseInsensitive) {
      term = term.toLowerCase();
      if (option == "startsWith")
        return data.filter(x =>
          (x.Name || x.PDFInfo.Name).toLowerCase().startsWith(term),
        );
      else if (option == "endsWith")
        return data.filter(x =>
          (x.Name || x.PDFInfo.Name).toLowerCase().endsWith(term),
        );
      else if (option == "includes")
        return data.filter(x =>
          (x.Name || x.PDFInfo.Name).toLowerCase().includes(term),
        );
      else
        return data.filter(
          x => (x.Name || x.PDFInfo.Name).toLowerCase() == term,
        );
    } else if (option == "startsWith")
      return data.filter(x => (x.Name || x.PDFInfo.Name).startsWith(term));
    else if (option == "endsWith")
      return data.filter(x => (x.Name || x.PDFInfo.Name).endsWith(term));
    else if (option == "includes")
      return data.filter(x => (x.Name || x.PDFInfo.Name).includes(term));
    else return data.filter(x => (x.Name || x.PDFInfo.Name) == term);
  };

  constructor() {
    super("name", "Name filter", false);
  }
}

class PresFilter extends Filter {
  Apply = (data: Session[], pres: number) => {
    return data.filter(x => x.PresentationId === pres);
  };

  constructor() {
    super("pres", "Presentation filter filter", false);
  }
}

// const scoreFilter = (
//   data: Session[],
//   minScore: number,
//   maxScore: number,
//   option: string,
// ) => {
//   if (option == "greater")
//     return data.filter(x => x.SessionScore * 100 >= minScore);
//   else if (option == "less")
//     return data.filter(x => x.SessionScore * 100 <= maxScore);
//   else
//     return data.filter(
//       x => x.SessionScore * 100 >= minScore && x.SessionScore * 100 <= maxScore,
//     );
// };

const filters: Filter[] = [
  new DateFilter(),
  new DurationFilter(),
  new NameFilter(),
  new PresFilter(),
];

export default filters;
