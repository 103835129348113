import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VCardTitle,[_c('div',{staticClass:"font-weight-bold text-truncate flex-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("dataChartTitle.scoreOverTime")))])]),_c('div',{staticClass:"mx-2"}),_c(VBtn,{staticClass:"mr-4",attrs:{"elevation":"0","link":"","to":"/sessions/progress"}},[_c('span',[_vm._v("View Progress")]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1),_c('InformationTooltip',{attrs:{"text":"scoreOverTime"}})],1),_c(VCardText,{staticClass:"text-danger text-right"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("dataChartTitle.changeOverTimeText")))]),_c(VSpacer),_c('span',{staticClass:"display-1 font-weight-bold text--lighten-2"},[_vm._v(_vm._s(_vm.change))])],1),(!_vm.loading)?_c('LineChart',{staticStyle:{"height":"300px","margin-right":"20px"},attrs:{"chart-data":_vm.chartData,"options":_vm.chartOptions}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }