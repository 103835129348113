




























































import Vue from "vue";
import { Getter } from "vuex-class";
import { ChartEntry } from "@/core/models";
import { Component } from "vue-property-decorator";
import { InformationTooltip } from "@/components/common";
import { getPercentageChartData } from "../util";
import DoughnutChartLabel from "@/components/charts/base-charts/DoughnutChartLabel.vue";
import { ChartOptions } from "chart.js";

@Component({
  components: {
    DoughnutChartLabel,
    InformationTooltip,
  },
})
export default class PausesRatio extends Vue {
  @Getter("currentSession/pauses") pauses!: ChartEntry[];
  @Getter("currentSession/pausesTotal") total!: number;
  @Getter("currentSession/pausesAvg") average!: number;
  @Getter("currentSession/length") sessionLength!: number;

  get count() {
    return this.pauses.length;
  }
  get percentage() {
    let perc = Math.round((this.total / this.sessionLength) * 10000) / 100;
    return perc;
  }
  get pausesPerMinute() {
    return Math.round(this.count / (this.sessionLength / 60));
  }

  get chartData() {
    let color1 = this.$vuetify.theme.themes.light.primary + "";
    let color2 = this.$vuetify.theme.themes.light.accent + "";
    return getPercentageChartData(this.percentage, [color1, color2]);
  }

  get label() {
    return Math.round(this.percentage);
  }

  chartOptions: ChartOptions = {
    tooltips: { enabled: false },
    cutoutPercentage: 60,
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
  };
}
