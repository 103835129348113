
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

@Component({
  extends: Doughnut,
  mixins: [reactiveProp],
})
export default class DoughnutChart extends Vue {
  @Prop() chartData!: object;
  @Prop({
    default: () => {
      return {};
    },
  })
  options!: object;

  renderChart!: (chartData: any, options: any) => void;

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
}
