
































import Vue from "vue";
import { Getter } from "vuex-class";
// @ts-ignore
import VueWordCloud from "vuewordcloud";
import { Component } from "vue-property-decorator";
import { InformationTooltip } from "@/components/common";
// @ts-ignore
import Wordcloud from "vue-wordcloud";

@Component({
  components: {
    Wordcloud,
    InformationTooltip,
    [VueWordCloud.name]: VueWordCloud,
  },
})
export default class KeywordsCard extends Vue {
  @Getter("theme") theme!: any;
  @Getter("currentSession/text") text!: string;
  @Getter("currentSession/keywords") keywords!: string[];

  get words() {
    const occurrences = new Map<string, number>();
    const words = this.text.split(/[ .,!?]+/).filter(x => !!x);
    for (const word of words) {
      const wordLowerCase = word.toLowerCase();

      if (occurrences.has(wordLowerCase))
        occurrences.set(wordLowerCase, occurrences.get(wordLowerCase)! + 1);
      else occurrences.set(wordLowerCase, 1);
    }

    return this.keywords.map(keyword => {
      const keywordLowerCase = keyword.toLowerCase();
      const weight = occurrences.get(keywordLowerCase) || 0;
      const originalCaseWord = this.text
        .split(/[ .,!?]+/)
        .find(word => word.toLowerCase() === keywordLowerCase);

      return {
        name: originalCaseWord || keyword,
        value: weight + keyword.length,
      };
    });
  }

  // Cloud Config
  get color() {
    if (!this.theme) return ["#ccc"];
    return [
      this.theme.primary,
      "#4fa4e5",
      "#6ba9d9",
      "#83accc",
      "#999",
      "#666",
    ];
  }
  rotate = { from: 0, to: 0, numOfOrientation: 1 };
  margin = { top: 0, right: 0, bottom: 0, left: 0 };
  wordMargin = 0;

  handleClick(word: string) {
    console.log(word);
  }
}
