























import Vue from "vue";
import { DateTime } from "luxon";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Session } from "@/core/models/sessions";
import { InformationTooltip } from "@/components/common";
import { LineChart } from "@/components/charts/base-charts";
import { getChartData, hexToRgba, getChangePercentage } from "../util";

@Component({
  components: { LineChart, InformationTooltip },
})
export default class PitchOverTime extends Vue {
  @Getter("theme") theme!: any;
  @Prop({ default: () => [] }) apiData!: Session[];

  get changeText() {
    return this.$t(
      "sessionsPage.sessionsProgressSection.pitchOverTimeCard.changeOverTime",
    );
  }
  get headline() {
    return this.$t(
      "sessionsPage.sessionsProgressSection.pitchOverTimeCard.pitchOverTime",
    );
  }

  get chartData() {
    let hexColor = this.$vuetify.theme.themes.light.primary + "";
    let color = hexToRgba(hexColor, 0.5);

    let labels = this.makeLabels();
    let data = this.getData();
    return getChartData(labels, data, color);
  }

  makeLabels() {
    return this.apiData.map(s => {
      const time = s.RecordingDate;
      const date = DateTime.fromJSDate(time, { locale: this.$i18n.locale });
      // @ts-ignore
      return date.toLocaleString(DateTime.DATE_MED as any);
    });
  }

  getData() {
    return this.apiData.map(s => {
      const pause = Math.round(s.Averages.averagePitch * 10) / 10;
      return pause;
    });
  }

  get change() {
    let data = this.getData();
    return getChangePercentage(data);
  }

  get chartOptions() {
    return {
      layout: { padding: { top: 10, bottom: 10, left: 10, right: 10 } },
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      tooltips: { displayColors: false },
      elements: {
        point: { radius: 4, hitRadius: 6, hoverRadius: 8 },
        line: { fill: true },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: { display: true, labelString: this.$t("charts.avgvp") },
            gridLines: {
              drawBorder: false,
              borderDash: [0, 0],
              color: this.theme.lightgray2,
            },
            ticks: { padding: 0, maxTicksLimit: 4, min: 0, beginAtZero: true },
          },
        ],
        xAxes: [
          {
            scaleLabel: { display: false },
            gridLines: { display: false, drawBorder: false },
            ticks: {
              display: false,
              padding: 10,
              maxTicksLimit: 5,
              beginAtZero: true,
              min: 0,
              maxRotation: 0,
            },
          },
        ],
      },
    };
  }
}
