
import Vue from "vue";
import { Line, mixins } from "vue-chartjs";
import { ChartClick } from "@/core/models";
// @ts-ignore
import DraggablePlugin from "chartjs-plugin-draggable";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  extends: Line,
  mixins: [mixins.reactiveProp],
})
export default class LineChart extends Vue {
  @Prop({ default: () => {} }) options!: any;
  @Prop({ default: () => {} }) chartData!: any;

  renderChart!: (chartData: any, options: any) => void;
  addPlugin!: (plugin: any) => void;
  update!: (config?: any) => void;

  handleClick(evt: any) {
    const point = this.$data._chart.getElementAtEvent(evt)[0];
    if (point) {
      const value = point._index + 1;
      this.$emit("chart-click", { type: "index", value } as ChartClick);
    } else {
      const { left, right, top, bottom } = this.$data._chart.chartArea;
      if (
        evt.offsetX > left &&
        evt.offsetX < right &&
        evt.offsetY > top &&
        evt.offsetY < bottom
      ) {
        const value = (evt.offsetX - left) / (right - left);
        this.$emit("chart-click", { type: "offset", value } as ChartClick);
      }
    }
  }

  @Watch("options")
  optionsChanged() {
    const opts = {
      ...this.options,
      onClick: this.handleClick,
    };
    this.renderChart(this.chartData, opts);
  }

  mounted() {
    const opts = {
      ...this.options,
      onClick: this.handleClick,
    };
    this.addPlugin(DraggablePlugin);
    this.addPlugin(AnnotationPlugin);
    this.renderChart(this.chartData, opts);
  }
}
