import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',[_vm._v(_vm._s(_vm.$t("dataChartTitle.movementRatio")))]),_c(VSpacer),_c('InformationTooltip',{attrs:{"text":"movementRatio"}})],1),_c(VDivider),_c(VRow,{staticClass:"py-6"},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('DoughnutChart',{staticStyle:{"height":"200px"},attrs:{"chart-data":_vm.chartData,"options":_vm.chartOptions}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VList,[_c(VListItem,[_c(VListItemContent,{staticClass:"text-right"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("dataChartTitle.totalHeadMovement")))]),_c('span',{staticClass:"display-1 font-weight-light"},[_vm._v(_vm._s(_vm.ratioData[0]))])])],1),_c(VListItem,[_c(VListItemContent,{staticClass:"text-right"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("dataChartTitle.totalHandMovement")))]),_c('span',{staticClass:"display-1 font-weight-light"},[_vm._v(_vm._s(_vm.ratioData[1]))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }