





























import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import { MovementAnalysis } from "@/core/models";
import MovementRatioCard from "../cards/MovementRatioCard.vue";
import { HandMovement, HeadMovement } from "@/components/charts";

@Component({ components: { HandMovement, HeadMovement, MovementRatioCard } })
export default class MovementAnalysisDetails extends Vue {
  @Getter("currentSession/movement") movement!: MovementAnalysis;

  get hasData() {
    return (
      this.movement.HandMovement.length > 0 &&
      this.movement.HeadMovement.length > 0
    );
  }
}
