import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.groups.length > 0)?_c(VList,{staticClass:"mt-2",attrs:{"expand":""}},_vm._l((_vm.groups),function(group,i){return _c('SharedWithItem',{key:("group-" + i),attrs:{"group":group,"selected":_vm.users},on:{"update:selected":function($event){_vm.users=$event}}})}),1):_c('div',{staticClass:"px-4"},[_vm._v(" "+_vm._s(_vm.$t("sess.noOneToShareWith"))+" ")]),(_vm.hangingUsers.length > 0)?_c('div',{staticClass:"mt-4"},[_c('h3',[_vm._v(_vm._s(_vm.$t("sess.hanging.title")))]),_c('p',[_vm._v(_vm._s(_vm.$t("sess.hanging.text")))]),_c('div',{staticClass:"px-4"},[_vm._l((_vm.hangingUsers),function(u,i){return _c('div',{key:i,staticClass:"d-flex flex-1 align-center"},[_c('div',{staticClass:"main--text font-weight-bold"},[_vm._v(_vm._s(u))]),_c(VSpacer),_c(VBtn,{staticStyle:{"border-radius":"5px"},attrs:{"tile":"","icon":"","color":"prezpRed","loading":_vm.toRemove === u && _vm.removeLoading,"disabled":_vm.toRemove === u && _vm.removeLoading},on:{"click":function($event){return _vm.toggleRemoveOne(u)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)}),_c(VDialog,{attrs:{"max-width":"300px"},model:{value:(_vm.removeOneDialog),callback:function ($$v) {_vm.removeOneDialog=$$v},expression:"removeOneDialog"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("sess.hanging.removeOneDialogTitle", { u: _vm.toRemove }))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("sess.hanging.removeOneDialogText", { u: _vm.toRemove }))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.toggleRemoveOne}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.removeAllHanging}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.confirm")))])])],1)],1)],1)],2),_c('div',{staticClass:"d-flex mt-2"},[_c(VSpacer),_c(VDialog,{attrs:{"max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"text":"","color":"prezpRed","loading":_vm.removeLoading,"disabled":_vm.removeLoading}},on),[_c('span',[_vm._v(_vm._s(_vm.$t("sess.hanging.removeAllButton")))])])]}}],null,false,2163577797),model:{value:(_vm.removeAllDialog),callback:function ($$v) {_vm.removeAllDialog=$$v},expression:"removeAllDialog"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("sess.hanging.removeAllDialogTitle"))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("sess.hanging.removeAllDialogText"))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.removeAllDialog = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.removeAllHanging}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.confirm")))])])],1)],1)],1)],1)]):_vm._e(),(_vm.hangingUsers.length > 0)?_c('div',{staticClass:"d-flex align-center mt-4"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("sess.clear.title")))]),_c('p',[_vm._v(_vm._s(_vm.$t("sess.clear.text")))])]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","disabled":_vm.users.length === 0},on:{"click":function($event){_vm.users = []}}},[_vm._v(" "+_vm._s(_vm.$t("global.clear"))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }