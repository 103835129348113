




























































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Session } from "@/core/models/sessions";
import SessionFilters from "@/components/sessions/SessionFilters.vue";

import {
  WPMOverTime,
  PitchOverTime,
  VolumeOverTime,
  PausesOverTime,
  SentimentOverTime,
  HeadMovementOverTime,
  HandMovementOverTime,
} from "@/components/charts";

@Component({
  components: {
    WPMOverTime,
    PitchOverTime,
    VolumeOverTime,
    SessionFilters,
    PausesOverTime,
    SentimentOverTime,
    HeadMovementOverTime,
    HandMovementOverTime,
  },
})
export default class SessionsProgressPage extends Vue {
  @Prop({ default: () => [] }) sessions!: Session[];

  limit = this.sessions.length;
  get values() {
    let len = this.sessions.length;
    if (len < 2) return [];
    const vals = new Array<number>(Math.floor(len / 2))
      .fill(0, 0)
      .map((_, i) => (i + 1) * 2);
    return len % 2 ? vals : [...vals, len];
  }

  @Watch("sessions.length")
  lenChanged() {
    this.limit = this.sessions.length - (this.sessions.length % 2);
  }

  get apiData() {
    if (!this.sessions.length) return [];
    return this.sessions.filter(x => x.Averages !== null).slice(0, this.limit);
  }
}
