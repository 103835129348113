
















import Vue from "vue";
import Pages from "@/core/utils/Pages";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PagesArrows extends Vue {
  @Prop({ default: () => false }) left!: boolean;
  @Prop({ default: () => Pages.Empty() }) pages!: Pages<any>;
  back = () => this.pages.setPage(this.pages.CurrentPageId - 1);
  fwd = () => this.pages.setPage(this.pages.CurrentPageId + 1);
}
