






























import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { Component } from "vue-property-decorator";

import { Session } from "@/core/models/sessions";

interface SelectItem {
  text: string;
  value: Session | null;
}

@Component
export default class BenchmarkSelect extends Vue {
  @Getter("sessions/benchmark") benchmark!: Session;
  @Getter("sessions/benchmarks") benchmarks!: Session[];
  @Getter("sessions/benchmarksRetrieved") retrieved!: boolean;
  @Getter("sessions/benchmarksLoading") loading!: boolean;
  @Action("sessions/getBenchmarks") getBenchmarks!: Function;
  @Action("sessions/setBenchmark") setBenchmark!: Function;

  benchOpen = false;
  changeBench(b: Session) {
    this.benchOpen = false;
    this.setBenchmark(b);
  }
  get items() {
    if (!this.benchmarks) return [];
    const benchmarks: SelectItem[] = this.benchmarks.map(b => ({
      text: b.Name,
      value: b,
    }));
    benchmarks.unshift({ text: "None", value: null });
    return benchmarks;
  }

  get benchText() {
    return this.benchmark === null
      ? this.$t("bench.choose")
      : this.$t("bench.change");
  }

  created() {
    if (!this.loading && !this.retrieved) this.getBenchmarks();
  }
}
