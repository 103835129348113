


















































import Vue from "vue";
import { Getter } from "vuex-class";
import { MovementAnalysis } from "@/core/models";
import { Component } from "vue-property-decorator";
import { InformationTooltip } from "@/components/common";

@Component({ components: { InformationTooltip } })
export default class MovementRatioCard extends Vue {
  @Getter("currentSession/movement") movement!: MovementAnalysis;

  get has() {
    return (
      this.movement.HandMovement.length > 0 &&
      this.movement.HeadMovement.length > 0
    );
  }
  get head() {
    let total = this.movement.TotalHeadMovement;
    if (total == 0)
      total = this.movement.HeadMovement.reduce(
        (cum, curr) => cum + curr.Value,
        0,
      );
    return Math.round(total * 100) / 100;
  }
  get hand() {
    let total = this.movement.TotalHandMovement;
    if (total == 0)
      total = this.movement.HandMovement.reduce(
        (cum, curr) => cum + curr.Value,
        0,
      );
    return Math.round(total * 100) / 100;
  }
  get headPercent() {
    const total = this.head + this.hand;
    return Math.round((this.head / total) * 100);
  }
  get handPercent() {
    const total = this.head + this.hand;
    return Math.round((this.hand / total) * 100);
  }
}
