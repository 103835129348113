



























































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { AnalysisStatus, Session } from "@/core/models";
import { formatFromSeconds } from "@/core/utils/formatTime";
import EditSessionDialog from "./EditSessionDialog.vue";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

@Component({ components: { EditSessionDialog, VideoPlayer } })
export default class SessionSelectItem extends Vue {
  @Prop({ default: () => false }) isOtherUser!: boolean;
  @Prop({ default: () => ({} as Session) }) session!: Session;

  @Getter("currentSession/id") currentSessionId!: number;
  @Getter("sessions/retrievedSessionId") retrievedSessionId!: number;
  @Getter("sessions/gettingSingleSession") retrievingSession!: boolean;

  openFeedback = false;

  showFeedback() {
    console.log("showFeedback");
    this.openFeedback = true;
  }

  get statusText() {
    const base = "sessionsPage.sessionsProgressSection.analysisStatus.";
    const str = base + this.session.AnalysisStatus;
    return this.$t(str);
  }

  get progress() {
    const status = this.session.AnalysisStatus;
    if (status === AnalysisStatus.AnalyzingAudio) return 7;
    else if (status === AnalysisStatus.AudioDone) return 15;
    else if (status === AnalysisStatus.AnalyzingText) return 33;
    else if (status === AnalysisStatus.TextDone) return 75;
    else if (status === AnalysisStatus.Succeeded) return 100;
    else return 0;
  }

  get sharedWith() {
    const count =
      (this.session.SharedWith && this.session.SharedWith.length) || 0;

    return count === 0
      ? this.$t("sess.private").toString()
      : count > 1
      ? this.$t("sess.shared", { n: count })
      : this.$t("sess.sharedWithOne", { n: count });
  }

  get selected() {
    return this.currentSessionId === this.session.ID;
  }

  get name() {
    return this.session.Name;
  }

  get length() {
    return formatFromSeconds(this.session.SessionLength);
  }
}
