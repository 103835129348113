import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"text":"","accent":""}},on),[_c('span',[_vm._v(_vm._s(_vm.benchText))])])]}}]),model:{value:(_vm.benchOpen),callback:function ($$v) {_vm.benchOpen=$$v},expression:"benchOpen"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("bench.title")))]),_c(VCardSubtitle,[_c('span',[_vm._v(_vm._s(_vm.$t("bench.text")))])]),_c(VCardText,[_c(VSelect,{attrs:{"items":_vm.items,"loading":_vm.loading},on:{"change":_vm.changeBench},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2"},[_vm._v(_vm._s(_vm.$t("global.nodata")))])]},proxy:true}])}),_c('div',{staticClass:"mt-4 d-flex"},[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0"},on:{"click":_vm.getBenchmarks}},[_c('span',[_vm._v("Refresh benchmarks")]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-refresh")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }