



















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { SentimentSentence } from "@/core/models/sessions";

@Component
export default class Sentence extends Vue {
  @Prop({ default: () => ({} as SentimentSentence) })
  sentence!: SentimentSentence;
}
