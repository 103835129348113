
























import Vue from "vue";
import { FilterUser } from "@/core/models";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import DeleteSession from "@/components/sessions/DeleteSession.vue";

@Component({ components: { DeleteSession } })
export default class SharedWithItem extends Vue {
  @PropSync("selected", { type: Array }) users!: string[];
  @Prop({ default: {} as FilterUser }) user!: FilterUser;

  get isIncluded() {
    return this.users.includes(this.user.email);
  }

  toggleUser() {
    if (this.isIncluded) {
      const idx = this.users.findIndex(x => x === this.user.email);
      this.users.splice(idx, 1);
    } else this.users.push(this.user.email);
  }

  checked = false;
  @Watch("users", { immediate: true })
  usesChanged() {
    this.checked = this.isIncluded;
  }
}
