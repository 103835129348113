




















import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch, Prop } from "vue-property-decorator";

// Models
import { SessionDetails, SessionDetailsSkeleton } from "@/components/sessions";

// Utils
import Pages from "@/core/utils/Pages";
import PagesArrows from "@/components/common/PagesArrows.vue";

// Components
import { Session } from "@/core/models";
import { AudioPlayer } from "@/components/common";
import SessionSelectItem from "./SessionSelectItem.vue";

@Component({
  components: {
    SessionDetails,
    SessionDetailsSkeleton,
    AudioPlayer,
    PagesArrows,
    SessionSelectItem,
  },
})
export default class SessionsSelect extends Vue {
  @Prop({ default: () => [] }) sessions!: Session[];
  @Prop({ default: () => false }) isOtherUser!: boolean;

  @Getter("showSnackbar") showSnackbar!: boolean;
  @Getter("snackbarText") snackbarText!: boolean;
  @Getter("goals/retrieved") goalsRetrieved!: boolean;
  @Getter("currentSession/id") currentSessionId!: number;
  @Getter("sessions/retrievedSessionId") retrievedSessionId!: number;
  @Getter("sessions/gettingSingleSession") retrievingSession!: boolean;

  @Action("sessions/getSlimSessions")
  getSessions!: () => Promise<void>;
  @Action("goals/getGoals")
  getGoals!: () => void;
  @Action("sessions/getSingleSession")
  getSession!: (id: number) => Promise<void>;
  @Action("currentSession/setSession")
  setCurrentSession!: (s: Session) => void;

  limit = 6;
  pages = new Pages<Session>(this.limit, this.sessions);

  @Watch("sessions")
  sessionsChanged() {
    this.pages = new Pages<Session>(6, this.sessions);
  }

  selectSession(session: Session) {
    if (this.currentSessionId === 0) this.$emit("changeTab");
    const selectedSession = this.sessions.find(x => x.ID === session.ID);
    if (selectedSession) {
      if (!selectedSession.Loaded && !this.isOtherUser)
        this.getSession(session.ID);
      this.setCurrentSession(selectedSession);
    }
  }

  mounted() {
    if (!this.pages || !this.sessions) return;
  }
}
