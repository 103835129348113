import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"primary",attrs:{"icon":"","color":"other","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function () { return (_vm.usersDialog = !_vm.usersDialog); }}},on),[_c(VIcon,[_vm._v("mdi-account-group")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("sess.sharing")))])]),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.usersDialog),callback:function ($$v) {_vm.usersDialog=$$v},expression:"usersDialog"}},[_c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c('div',{staticStyle:{"flex":"1"}},[_vm._v(_vm._s(_vm.$t("sess.usersSharing")))]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.usersDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(_vm.selected.length != 0)?_c(VCardText,[_vm._v(" "+_vm._s(_vm.selected.join(", "))+" ")]):_vm._e(),(!_vm.loading)?_c(VCardText,[(_vm.users.length > 0)?_c(VList,{staticClass:"mt-2",attrs:{"expand":""}},_vm._l((_vm.users),function(user,i){return _c(VListItem,{key:("user-" + i),attrs:{"value":user.email},on:{"click":function($event){return _vm.getUser(user.email)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(user.username))]),_c(VListItemSubtitle,[_vm._v(_vm._s(user.email))])],1),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)}),1):_vm._e(),(_vm.users.length === 0)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t("global.none")))]),_c('div',{staticClass:"d-flex flex-column align-center"},[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":_vm.getUsers}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_c('span',[_vm._v(_vm._s(_vm.$t("global.retry")))])],1)],1)]):_vm._e()],1):_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("global.loading"))+" ")]),(_vm.active)?_c(VCardActions,[_c(VSpacer),(_vm.active)?_c(VBtn,{attrs:{"text":"","link":"","to":"/sessions/shared"}},[_c('span',[_vm._v(_vm._s(_vm.$t("sess.sharedback")))])]):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }