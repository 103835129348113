










































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { SentimentRatio } from "@/components/charts";
import LookupWords from "@/components/sessions/cards/LookupWords.vue";
import KeywordsCard from "@/components/sessions/cards/KeywordsCard.vue";
import SentimentSentences from "@/components/sessions/cards/SentimentSentences.vue";

@Component({
  components: {
    LookupWords,
    KeywordsCard,
    SentimentRatio,
    SentimentSentences,
  },
})
export default class TextAnalysisDetails extends Vue {
  @Getter("currentSession/volumeAvg") volumeAvg!: number;
  @Getter("currentSession/textCompleted") isCompleted!: boolean;
}
