












































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { getPieChartData } from "../util";
import { InformationTooltip } from "@/components/common";
import { MovementAnalysis } from "@/core/models/sessions";
import DoughnutChart from "@/components/charts/base-charts/DoughnutChart.vue";

@Component({
  components: { DoughnutChart, InformationTooltip },
})
export default class MovementRatio extends Vue {
  @Prop({ default: () => null }) apiData!: MovementAnalysis;

  get labels(): string[] {
    return ["Head Movement", "Hand Movement"];
  }
  get ratioData(): number[] {
    return [this.apiData.TotalHeadMovement, this.apiData.TotalHandMovement];
  }

  get chartData(): object {
    let color1 = this.$vuetify.theme.themes.light.primary + "";
    let color2 = this.$vuetify.theme.themes.light.secondary + "";
    return getPieChartData(this.labels, this.ratioData, [color1, color2]);
  }

  chartOptions: object = {
    tooltips: { enabled: false, displayColors: false },
    cutoutPercentage: 25,
    responsive: true,
    maintainAspectRatio: false,
  };
}
